import { ReactNode } from 'react';

import { PortalProvider } from '@dansk-metal/ui';

import { ReactQueryProvider } from '@apps/web/src/components/providers/react-query';
import { ReactResponsiveProvider } from '@apps/web/src/components/providers/react-responsive';

type ProviderProps = { children: ReactNode };

const Providers = ({ children }: ProviderProps) => {
	return (
		<ReactQueryProvider>
			<ReactResponsiveProvider>
				<PortalProvider>{children}</PortalProvider>
			</ReactResponsiveProvider>
		</ReactQueryProvider>
	);
};

export default Providers;
