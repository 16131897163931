import React from 'react';
import { Fac, FacType } from '@noaignite-dk/utils-next';
import classNames from 'classnames';

import { translation } from '@dansk-metal/service/translations';

import styles from './label.module.scss';

export type LabelProps = {
	labelPosition?: 'before' | 'after' | 'over' | 'under';
	label?: React.ReactNode;
	/**
	 * The children can either be ReactNode or a function that has the label as a prop and returns a ReactNode
	 * This is useful if you need a special place to render the label
	 */
	children?: FacType<{ label: React.ReactNode; description: React.ReactNode }>;
	/**
	 * If the Label should have a `for` attribute.
	 */
	id?: string;
	className?: string;
	required?: boolean;
	disabled?: boolean;
	/**
	 * An additional description of the field.
	 */
	description?: React.ReactNode;
	refs?: React.Ref<HTMLLabelElement>;

	noOptionalLabel?: boolean;
};

/**
 * Label component
 *
 * @version 1.0.0
 */
export const Label = ({
	labelPosition = 'over',
	label,
	children,
	className,
	description,
	required,
	disabled,
	id,
	refs,
	noOptionalLabel,
	...rest
}: LabelProps & React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement>) => {
	const isChildrenFunction = Fac.isFac(children);

	const labelSpan = <LabelSpan
		label={label}
		required={required}
		noOptionalLabel={noOptionalLabel}
	/>;

	return (
		<label
			className={classNames(styles.label, className, { [styles.margin]: !description })}
			htmlFor={id}
			aria-disabled={disabled}
			data-label-position={labelPosition}
			data-label
			ref={refs}
			{...rest}
		>
			{label && !isChildrenFunction && (labelPosition === 'before' || labelPosition === 'over') && labelSpan}

			{description && !isChildrenFunction && <DescriptionField description={description} />}

			<Fac
				render={children}
				props={{
					label: <LabelSpan label={label} required={required} />,
					description: <DescriptionField description={description} />,
				}}
			/>

			{label && !isChildrenFunction && (labelPosition === 'after' || labelPosition === 'under') && labelSpan}
		</label>
	);
};

export const OptionalLabel = () => (
	<span className={styles['optional-label']} data-optional-label>
		{translation.common.form.optionalLabel}
	</span>
);

export function LabelSpan({
	label,
	required,
	noOptionalLabel,
	className,
}: Pick<LabelProps, 'label' | 'required' | 'className' | 'noOptionalLabel'>) {
	return (
		<span data-label-span className={className}>
			{label}
			{!required && !noOptionalLabel && <OptionalLabel />}
		</span>
	);
}

export function DescriptionField({ description, className }: Pick<LabelProps, 'description' | 'className'>) {
	return (
		<p data-description className={classNames(styles.description, className)}>
			{description}
		</p>
	);
}
