import React from 'react';
import { TooltipPopup, useTooltip } from '@reach/tooltip';
import classNames from 'classnames';

import './tooltip.scss';
import styles from './tooltip.module.scss';

export type TooltipProps = {
	children: React.ReactNode;
	label: React.ReactNode;
	tooltip?: ReturnType<typeof useTooltip>;
	className?: string;
};

/**
 * Tooltip Component
 *
 * #### Simple Tooltip
 *
 * You simply wrap your component with the Tooltip component and pass the label prop to show a tooltip
 * The tooltip does currently not support array of children, so you need to wrap your children in a fragment
 *
 * @example
 * ```tsx
    const Component = () => (
      <Tooltip>
        <Button>I'm a button</Button>
      </Tooltip>
    )
 * ```
 *
 * #### React.ReactNode Tooltip
 *
 * The Label prop can also be a React.ReactNode, so you can pass in a component or a string
 *
 * @example
 * ```tsx
    const Component = () => (
      <Tooltip label={<p>I'm a paragraph with <strong>strong</strong> text</p>}>
        <Button>I'm a button</Button>
      </Tooltip>
    )
 * ```
 *
 * #### Controlled Tooltip
 *
 * You can also use the useTooltip hook to control the tooltip yourself
 *
 * @example
 * ```tsx
    const Component = () => {
      const tooltip = Tooltip.useTooltip();

      return (
        <Tooltip tooltip={tooltip}>
          <Button>I'm a button</Button>
        </Tooltip>
      )
    }
 * ```
 */
export const Tooltip = ({ children, label, className, tooltip: tooltipProp, ...rest }: TooltipProps) => {
	const [trigger, tooltip] = tooltipProp || Tooltip.useTooltip();

	return (
		<React.Fragment>
			{children && Array.isArray(children) ? (
				<>{children.map((child) => React.cloneElement(child as React.ReactElement, trigger))}</>
			) : (
				React.cloneElement(children as React.ReactElement, trigger)
			)}
			<TooltipPopup
				{...tooltip}
				label={label}
				data-visible={tooltip.isVisible}
				className={classNames(styles.popup, className)}
				{...rest}
			/>
		</React.Fragment>
	);
};

Tooltip.useTooltip = useTooltip;
