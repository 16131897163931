import { ControllerFieldState } from 'react-hook-form';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';

import { MOTIONS } from '@dansk-metal/utils/animations';
import { usePreserveString } from '@dansk-metal/utils/next';

import styles from './field-error.module.scss';

export type FieldErrorProps = { className?: string } & Pick<ControllerFieldState, 'error'>;

const animation = MOTIONS['fade']['fade']();

/**
 * FieldError component
 */
export const FieldError = ({ error, className }: FieldErrorProps) => {
	const preservedError = usePreserveString(error?.message);

	return (
		<AnimatePresence>
			{error && (
				<motion.p {...animation} className={classNames(styles.error, className)} data-error>
					{preservedError}
				</motion.p>
			)}
		</AnimatePresence>
	);
};
