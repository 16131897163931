import { useEffect, useRef } from 'react';
import { useRouter } from 'next/router';

export function useScrollRestore() {
	const router = useRouter();
	const scrollCache = useRef<Map<string, [number, number]>>(new Map());
	const activeRestorePath = useRef<string>();

	useEffect(() => {
		if (window?.history.scrollRestoration !== 'manual') {
			// Ensure scrollRestoration is set to manual,
			// if for some reason next.config.js's scrollRestoration prop failed to apply.
			// This shouldn't ever happen.
			window.history.scrollRestoration = 'manual';
		}

		function getCurrentPath() {
			return window.location.pathname + window.location.search;
		}

		router.beforePopState(() => {
			activeRestorePath.current = getCurrentPath();
			return true;
		});

		const onComplete = (_route: string, { shallow }: { shallow: boolean }) => {
			if (shallow) {
				return;
			}

			// In order to support instant scrollRestore, we swap the default scrollBehavior (smooth), with 'auto' temporarily.
			// It might be possible to use `window.scrollTo({ behvaior: 'instant' })` in the future,
			// but it's not supported in Safari at the moment.
			const initialScrollBehavior = document.body.style.scrollBehavior;
			document.body.style.scrollBehavior = 'auto';

			const scrollPath = activeRestorePath.current;

			if (!scrollPath || !scrollCache.current.has(scrollPath)) {
				window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
				return;
			}

			activeRestorePath.current = undefined;
			const [scrollX, scrollY] = scrollCache.current.get(scrollPath) || [0, 0];

			window.scrollTo({ top: scrollY, left: scrollX, behavior: 'auto' });

			// sometimes rendering the page can take a bit longer
			const delays = [10, 20, 40, 80, 160];

			function checkAndScroll() {
				if (
					(window.scrollX === scrollX && window.scrollY === scrollY)
					|| scrollPath !== getCurrentPath()
				) {
					return;
				}

				window.scrollTo(scrollX, scrollY);
				const delay = delays.shift();
				if (delay) {
					setTimeout(checkAndScroll, delay);
				}
			}

			setTimeout(checkAndScroll, delays.shift());
			document.body.style.scrollBehavior = initialScrollBehavior;
		};

		function onScroll() {
			const current = getCurrentPath();
			scrollCache.current.set(current, [window.scrollX, window.scrollY]);
		}

		router.events.on('routeChangeComplete', onComplete);
		window.addEventListener('scroll', onScroll);

		return () => {
			router.events.off('routeChangeComplete', onComplete);
			window.removeEventListener('scroll', onScroll);
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps -- only run on mount
	}, []);
}
