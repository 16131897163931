/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable @typescript-eslint/indent */

export const icons = {
  'account-alt': require('./assets/account-alt.svg').ReactComponent,
  'account': require('./assets/account.svg').ReactComponent,
  'arrow-down': require('./assets/arrow-down.svg').ReactComponent,
  'arrow-left-wide': require('./assets/arrow-left-wide.svg').ReactComponent,
  'arrow-left': require('./assets/arrow-left.svg').ReactComponent,
  'arrow-left-color': require('./assets/arrow-left-color.svg').ReactComponent,
  'arrow-right-wide': require('./assets/arrow-right-wide.svg').ReactComponent,
  'arrow-right': require('./assets/arrow-right.svg').ReactComponent,
  'arrow-up': require('./assets/arrow-up.svg').ReactComponent,
  'attach': require('./assets/attach.svg').ReactComponent,
  'badge': require('./assets/badge.svg').ReactComponent,
  'book': require('./assets/book.svg').ReactComponent,
  'bullet-list-item': require('./assets/bullet-list-item.svg').ReactComponent,
  'calendar': require('./assets/calendar.svg').ReactComponent,
  'cc': require('./assets/cc.svg').ReactComponent,
  'checkmark': require('./assets/checkmark.svg').ReactComponent,
  'checkmark-confirmation': require('./assets/checkmark-confirmation.svg').ReactComponent,
  'checkmark-fancy': require('./assets/checkmark-fancy.svg').ReactComponent,
  'checkmark-fat': require('./assets/checkmark-fat.svg').ReactComponent,
  'chevron-down': require('./assets/chevron-down.svg').ReactComponent,
  'chevron-left': require('./assets/chevron-left.svg').ReactComponent,
  'chevron-right': require('./assets/chevron-right.svg').ReactComponent,
  'chevron-up': require('./assets/chevron-up.svg').ReactComponent,
  'comment': require('./assets/comment.svg').ReactComponent,
  'computer': require('./assets/computer.svg').ReactComponent,
  'dismiss': require('./assets/dismiss.svg').ReactComponent,
  'download': require('./assets/download.svg').ReactComponent,
  'edit': require('./assets/edit.svg').ReactComponent,
  'email': require('./assets/email.svg').ReactComponent,
  'facebook': require('./assets/facebook.svg').ReactComponent,
  'factory': require('./assets/factory.svg').ReactComponent,
  'fullscreen': require('./assets/fullscreen.svg').ReactComponent,
  'gear': require('./assets/gear.svg').ReactComponent,
  'globe': require('./assets/globe.svg').ReactComponent,
  'house': require('./assets/house.svg').ReactComponent,
  'info': require('./assets/info.svg').ReactComponent,
  'li-arrow': require('./assets/li-arrow.svg').ReactComponent,
  'linkedin': require('./assets/linkedin.svg').ReactComponent,
  'list-alt': require('./assets/list-alt.svg').ReactComponent,
  'location-filled': require('./assets/location-filled.svg').ReactComponent,
  'location': require('./assets/location.svg').ReactComponent,
  'lock': require('./assets/lock.svg').ReactComponent,
  'logo': require('./assets/logo.svg').ReactComponent,
  'mail': require('./assets/mail.svg').ReactComponent,
  'medal': require('./assets/medal.svg').ReactComponent,
  'membership': require('./assets/membership.svg').ReactComponent,
  'menu': require('./assets/menu.svg').ReactComponent,
  'payment-cards': require('./assets/payment-cards.svg').ReactComponent,
  'payment-mobile-pay': require('./assets/payment-mobile-pay.svg').ReactComponent,
  'payment-service': require('./assets/payment-service.svg').ReactComponent,
  'minus': require('./assets/minus.svg').ReactComponent,
  'phone': require('./assets/phone.svg').ReactComponent,
  'play-filled': require('./assets/play-filled.svg').ReactComponent,
  'play': require('./assets/play.svg').ReactComponent,
  'plus': require('./assets/plus.svg').ReactComponent,
  'question-mark': require('./assets/question-mark.svg').ReactComponent,
  'quote': require('./assets/quote.svg').ReactComponent,
  'refresh': require('./assets/refresh.svg').ReactComponent,
  'remove-file': require('./assets/remove-file.svg').ReactComponent,
  'search': require('./assets/search.svg').ReactComponent,
  'settings': require('./assets/settings.svg').ReactComponent,
  'sound': require('./assets/sound.svg').ReactComponent,
  'subscriptions': require('./assets/subscriptions.svg').ReactComponent,
  'tick-alt-color': require('./assets/tick-alt-color.svg').ReactComponent,
  'tick-alt': require('./assets/tick-alt.svg').ReactComponent,
  'tick': require('./assets/tick.svg').ReactComponent,
  'ticket': require('./assets/ticket.svg').ReactComponent,
  'time': require('./assets/time.svg').ReactComponent,
  'twitter': require('./assets/twitter.svg').ReactComponent,
  'unchecked': require('./assets/unchecked.svg').ReactComponent,
  'videoPlay': require('./assets/videoPlay.svg').ReactComponent,
  'wallet': require('./assets/wallet.svg').ReactComponent,
  'youtube': require('./assets/youtube.svg').ReactComponent,
  'spinner': require('./assets/loader.svg').ReactComponent,
  'instagram': require('./assets/instagram.svg').ReactComponent,
};

export type Icons = keyof typeof icons;

export const iconsKey = Object.keys(icons) as Icons[];
