export function kebabToCamelCase(str: string) {
	// Check if the input string consists of only hyphens
	if (/^[-]+$/.test(str)) {
		return '';
	}

	return str.replace(/-([a-z])/g, (match, letter) => {
		return letter.toUpperCase();
	});
}

export function toTitleCase(str: string) {
	return str.replace(/[\wåøæÅØÆ]\S*/g, (txt) => {
		return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
	});
}

export function slugify(str: string): string {
	return str
		.toLowerCase()
		.trim()
		.replace(/[^\w\s-]/g, '')
		.replace(/[\s_-]+/g, '-')
		.replace(/^-+|-+$/g, '');
}
