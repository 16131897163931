import { entries } from '@dansk-metal/utils/common';

import { variablesVars } from './variables';

const variablesVarsEntries = entries(variablesVars);

/**
 * Storybook helper function to get the CSS variables entries.
 *
 * @param variables {@see keyof typeof variablesVars}
 */
export function getVariablesEntries(variables: RegExp) {
	return variablesVarsEntries.filter(([key]) => variables.test(key));
}

type GroupedVariablesEntries = Record<string, string[] & Record<string, string[] & Record<string, string[]>>>;

export function variablesEntriesToGroup(entriesValues: [string, string][], splitter: string) {
	return Object.entries(entriesValues.reduce((acc, [key]) => {
		const types = key.split(splitter)[1].split('-');
		const [type, color] = types;

		if (types.length === 1) {
			return { ...acc, base: [...(acc?.['base'] || []), key] } as unknown as GroupedVariablesEntries;
		}

		if (types.length === 2) {
			return {
				...acc,
				[type]: [...(acc?.[type] || []), key],
			} as GroupedVariablesEntries;
		}

		if (types.length === 3) {
			return {
				...acc,
				[type]: [
					...(acc?.[type] || []),
					[...(acc?.[type]?.[color] || []), key],
				],
			} as GroupedVariablesEntries;
		}

		return acc;
	}, {} as GroupedVariablesEntries));
}
