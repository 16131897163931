import { useEffect } from 'react';
import { AppProps } from 'next/app';
import { SessionProvider } from 'next-auth/react';
import { GoogleTagManager } from '@next/third-parties/google';

import Config from '@web/config';

import Providers from '@web/components/providers';

import { raleway } from '@web/constants/fonts';

import { useCookieconsent } from '@web/services/cookieinformation';
import { CookieInformationScript } from '@web/services/cookieinformation/script';
import { useInitGoogleAds } from '@web/services/google-ads';
import { SiteImproveScript } from '@web/services/siteimprove/script';

import { useScrollRestore } from '@web/hooks/use-scroll-restore';

import '@web/utils/wdyr';

import './global.scss';

if (Config.API_MOCKING) {
	// eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
	require('../services/mocks/index');
}

function CustomApp({ Component, pageProps }: AppProps) {
	useScrollRestore();
	const { marketing } = useCookieconsent();

	useInitGoogleAds(Config.googleAds.containerId, { enable: Config.googleAds.enabled && !!marketing });

	/** Load container query polyfill */
	useEffect(() => {
		if (document) {
			const supportsContainerQueries = 'container' in document.documentElement.style;
			if (!supportsContainerQueries) {
				try {
					import('container-query-polyfill');
				} catch (error) {
					console.error('Container queries are not supported in this browser', error);
				}
			}
		}
	}, []);

	return (
		<SessionProvider>
			<style jsx global>{`
				:root {
					--font-raleway: ${raleway.style.fontFamily};
				}
			`}</style>
			{Config.google.tagManager.id && (
				<GoogleTagManager gtmId={Config.google.tagManager.id} />
			)}
			<Providers>
				<Component {...pageProps} />
			</Providers>
			<SiteImproveScript />
			<CookieInformationScript />
		</SessionProvider>
	);
}

export default CustomApp;
