import { useCallback, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import union from 'lodash/union';
import without from 'lodash/without';

export type UseDisclosureConfig = {
	/** Whether the disclosure initial state should be true or open */
	initialOpen?: boolean;
	/** Callback when opening */
	onOpen?: () => void;
	/** Callback when closing */
	onClose?: () => void;
};

/**
 * Utility Disclosure State Hook for opening/closing
 *
 * @author @mthines
 *
 * @version 1.0.0
 */
export const useDisclosure = ({ initialOpen, onOpen, onClose }: UseDisclosureConfig = {}) => {
	const [isOpen, setOpen] = useState(initialOpen || false);

	const handleOpen = useCallback(() => {
		onOpen?.();
		setOpen(true);
	}, [onOpen]);

	const handleClose = useCallback(() => {
		onClose?.();
		setOpen(false);
	}, [onClose]);

	const handleToggle = useCallback(() => {
		if (isOpen) {
			handleClose();
			return;
		}

		handleOpen();
	}, [isOpen, handleClose, handleOpen]);

	return { isOpen, handleOpen, handleClose, handleToggle };
};

export type UseDisclosure = ReturnType<typeof useDisclosure>;

export type UseListDisclosureConfig<T = string> = (
	| {
		/** The ID's which should be initially open */
		initialOpen?: T[];
		/** Whether multiple ID's can be open at the same time. */
		multiple?: true;
	}
	| {
		/** The ID's which should be initially open */
		initialOpen?: T[];
		/** Whether multiple ID's can be open at the same time. */
		multiple?: false;
	}
) & {
	/** Callback when opening a specific item */
	onOpen?: () => void;
	/** Callback when closing a specific item */
	onClose?: () => void;
};

/**
 * Utility Disclosure State Hook for opening/closing multiple connected items.
 *
 * @author @mthines
 *
 * @version 1.0.0
 */
export const useListDisclosure = <T = string>(
	{ initialOpen, multiple, onOpen, onClose }: UseListDisclosureConfig<T> = {
		multiple: false,
	},
) => {
	const [activeIds, setActiveIds] = useState<T[] | undefined>(initialOpen);
	const isOpen = (id: T) => activeIds?.includes(id) || false;

	const handleOpen = (id: T) => () => {
		if (multiple && !isEmpty(activeIds)) {
			setActiveIds(union(activeIds, [id]));
			return;
		}

		onOpen?.();

		setActiveIds([id]);
	};

	const handleClose = (id: T) => () => {
		if (multiple && !isEmpty(activeIds)) {
			setActiveIds(without(activeIds, id));
			return;
		}

		onClose?.();

		setActiveIds([]);
	};

	const handleToggle = (id: T) => () => {
		if (activeIds?.includes(id)) {
			// Needs to be invoke twice because it's a curry function
			handleClose(id)();
			return;
		}

		// Needs to be invoke twice because it's a curry function
		handleOpen(id)();
	};

	/** Close all of the open items */
	const handleReset = () => setActiveIds([]);

	return { isOpen, handleOpen, handleClose, handleToggle, handleReset, activeIds, setActiveIds };
};

export type ListDisclosure = ReturnType<typeof useListDisclosure>;
