import { ChangeEventHandler, forwardRef, InputHTMLAttributes, Ref, useCallback, useEffect, useState } from 'react';
import { ControllerFieldState, ControllerRenderProps } from 'react-hook-form';
import cn from 'classnames';
import { OmitStrict } from 'type-zoo';

import { Icon, Icons } from '@dansk-metal/ui';
import { ExtendableForwardRef } from '@dansk-metal/utils/common';

import { FieldError } from '../field-error/field-error';
import { FieldProps } from '../form/form';
import { DescriptionField, Label, LabelProps, LabelSpan, OptionalLabel } from '../label/label';

import styles from './radio.module.scss';

export type RadioOption<Id extends string, T extends object | undefined = {}> = {
	value: Id;
	label: string;
	icon?: Icons;
} & Pick<LabelProps, 'description' | 'label'> &
	T;

export type RadioGroupValue = {
	[key: string]: boolean;
};

export type RadioGroupProps<TKey extends string, Id extends string, T extends object> = {
	fieldState?: ControllerFieldState;
	options: RadioOption<Id, T>[];
	checked?: RadioOption<Id, T>['value'];
	horizontal?: boolean;
	noOptionalLabel?: boolean;
	value?: RadioGroupValue;
} & Pick<LabelProps, 'description' | 'label'> &
	OmitStrict<InputHTMLAttributes<HTMLInputElement>, 'form' | 'value' | 'checked'> &
	FieldProps<TKey> &
	Partial<OmitStrict<ControllerRenderProps, 'ref' | 'value'>>;

export const RadioItem = forwardRef(
	<T extends object, Id extends string>(
		{
			autoFocus,
			checked,
			disabled,
			id,
			name,
			onBlur,
			onChange,
			onClick,
			onFocus,
			option,
			required,
			noOptionalLabel,
			...rest
		}: { option: RadioOption<Id, T> } & OmitStrict<
			RadioGroupProps<string, Id, T>,
			'horizontal' | 'className' | 'options'
		>,
		ref: Ref<HTMLInputElement>,
	) => {
		const defaultChecked = checked === undefined
			? rest && rest.value && rest.value[option?.value] === true
			: undefined;

		return (
			<label key={option.value} htmlFor={id || option.value} className={styles.label} aria-disabled={disabled}>
				<div className={styles.wrapper}>
					<input
						ref={ref}
						autoFocus={autoFocus}
						checked={checked !== undefined ? checked === option?.value : undefined}
						className={styles.input}
						data-radio-input
						disabled={disabled}
						id={id || option.value}
						name={name || id}
						onBlur={onBlur}
						onChange={onChange}
						onClick={onClick}
						onFocus={onFocus}
						aria-required={required}
						type="radio"
						{...rest}
						// Note: Keep value last to override any value passed in rest
						value={option.value}
						defaultChecked={defaultChecked}
					/>
					<span data-mark className={styles.mark}>
						<span className={cn(styles.dot, styles.icon)} />
					</span>
					<LabelSpan label={option.label} noOptionalLabel={noOptionalLabel} />
					{required && !noOptionalLabel && (
						<OptionalLabel />
					)}
				</div>
				{option?.icon && (
					<Icon className={styles.label_icon} icon={option?.icon} size={null} />
				)}
				{option.description && (
					<DescriptionField description={option.description} />
				)}
			</label>
		);
	},
);

/**
 * Radio component
 *
 * @version 1.0.0
 */
export const RadioGroup: ExtendableForwardRef<
	RadioGroupProps<string, string, {}>,
	{ useRadio?: typeof useRadio }
> = forwardRef(
	<TKey extends string, Id extends string, T extends object>(
		{ className, horizontal, label, description, options, noOptionalLabel, fieldState, ...props }: RadioGroupProps<TKey, Id, T>,
		ref: Ref<HTMLInputElement>,
	) => {
		return (
			<fieldset className={className}>
				{label && <Label className={styles.label} labelPosition="over" label={label} noOptionalLabel={noOptionalLabel} />}
				<div className={cn(styles.group, { [styles.horizontal]: horizontal })}>
					{options?.map((option) => (
						<RadioItem {...props} id={`${props.name}_${option.value}`} ref={ref} option={option} key={option.value} noOptionalLabel={noOptionalLabel} />
					))}
				</div>
				{description && <DescriptionField description={description} />}
				<FieldError className={styles.error} error={fieldState?.error} />
			</fieldset>
		);
	},
);

function useRadio<Id extends string>(config?: {
	initialChecked?: Id;
	onChecked?: () => void;
	onUnchecked?: () => void;
}) {
	const [checked, setChecked] = useState<Id | null>(config?.initialChecked || null);

	const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>((e) => {
		setChecked(e.target.id as Id);
	}, []);

	useEffect(() => {
		if (config?.onChecked && checked) {
			config.onChecked();
		}

		if (config?.onUnchecked && !checked) {
			config.onUnchecked();
		}
	}, [checked]);

	return {
		onChange: handleChange,
		checked: checked as Id,
	};
}

RadioGroup.useRadio = useRadio;
