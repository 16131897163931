import { TRANSITION } from './transitions';

type Distance = string | number;

export const MOTION_IN = {
	/**
	 * Fade in
	 */
	fade: () => ({
		initial: { opacity: 0 },
		animate: { opacity: 1 },
	}),
	/**
	 * Fade in `Left` from `Right`
	 */
	left: (distance: Distance = 10) => ({
		initial: { opacity: 0, x: distance },
		animate: { opacity: 1, x: 0 },
	}),
	/**
	 * Fade in `Right` from `Left`
	 */
	right: (distance: Distance = 10) => ({
		initial: { opacity: 0, x: -distance },
		animate: { opacity: 1, x: 0 },
	}),
	/**
	 * Fade in `Up` from `Down`
	 */
	up: (distance: Distance = 10) => ({
		initial: { opacity: 0, y: distance },
		animate: { opacity: 1, y: 0 },
	}),
	/**
	 * Fade in `Down` from `Up`
	 */
	down: (distance: Distance = 10) => ({
		initial: { opacity: 0, y: -distance },
		animate: { opacity: 1, y: 0 },
	}),
};

export const MOTION_OUT = {
	/**
	 * Fade out
	 */
	fade: () => ({
		exit: { opacity: 0 },
	}),
	/**
	 * Fade out `Left` from `Right`
	 */
	left: (distance: Distance = 10) => ({
		exit: { opacity: 0, x: -distance },
	}),
	/**
	 * Fade out `Right` from `Left`
	 */
	right: (distance: Distance = 10) => ({
		exit: { opacity: 0, x: distance },
	}),
	/**
	 * Fade out `Up` from `Down`
	 */
	up: (distance: Distance = 10) => ({
		exit: { opacity: 0, y: -distance },
	}),
	/**
	 * Fade out `Down` from `Up`
	 */
	down: (distance: Distance = 10) => ({
		exit: { opacity: 0, y: distance },
	}),
	shrink: () => ({
		exit: { height: 0, overflow: 'hidden', transition: { duration: 0.35 } },
	}),
};

/**
 * Framer Motion animation builder
 *
 * This is a collection of different motions, separated by [`in`][`out`]
 *
 * @example
 * ```tsx
 * //                      IN      OUT
 * <motion.div {...MOTIONS['fade']['out-right']}> ... </div>
 * ```
 *
 * @example
 * ```tsx
 * //                      IN         OUT
 * <motion.div {...MOTIONS['in-left']['out-left']}> ... </div>
 * ```
 *
 * @see https://www.framer.com/docs/introduction/#animation
 */
export const MOTIONS = {
	fade: {
		fade: () => ({
			transition: TRANSITION.quick,
			...MOTION_IN['fade'](),
			...MOTION_OUT['fade'](),
		}),
		'out-right': (distance?: Distance) => ({
			transition: TRANSITION.quick,
			...MOTION_IN['fade'](),
			...MOTION_OUT['right'](distance),
		}),
		'out-left': (distance?: Distance) => ({
			transition: TRANSITION.quick,
			...MOTION_IN['fade'](),
			...MOTION_OUT['left'](distance),
		}),
		'out-up': (distance?: Distance) => ({
			transition: TRANSITION.quick,
			...MOTION_IN['fade'](),
			...MOTION_OUT['up'](distance),
		}),
		'out-down': (distance?: Distance) => ({
			transition: TRANSITION.quick,
			...MOTION_IN['fade'](),
			...MOTION_OUT['down'](distance),
		}),
	},
	'in-left': {
		fade: (distance?: Distance) => ({
			transition: TRANSITION.quick,
			...MOTION_IN['left'](distance),
			...MOTION_OUT['fade'](),
		}),
		'out-right': (distance?: Distance) => ({
			transition: TRANSITION.quick,
			...MOTION_IN['left'](distance),
			...MOTION_OUT['right'](distance),
		}),
		'out-left': (distance?: Distance) => ({
			transition: TRANSITION.quick,
			...MOTION_IN['left'](distance),
			...MOTION_OUT['left'](distance),
		}),
		'out-up': (distance?: Distance) => ({
			transition: TRANSITION.quick,
			...MOTION_IN['left'](distance),
			...MOTION_OUT['up'](distance),
		}),
		'out-down': (distance?: Distance) => ({
			transition: TRANSITION.quick,
			...MOTION_IN['left'](distance),
			...MOTION_OUT['down'](distance),
		}),
	},
	'in-right': {
		fade: (distance?: Distance) => ({
			transition: TRANSITION.quick,
			...MOTION_IN['right'](distance),
			...MOTION_OUT['fade'](),
		}),
		'out-right': (distance?: Distance) => ({
			transition: TRANSITION.quick,
			...MOTION_IN['right'](distance),
			...MOTION_OUT['right'](distance),
		}),
		'out-left': (distance?: Distance) => ({
			transition: TRANSITION.quick,
			...MOTION_IN['right'](distance),
			...MOTION_OUT['left'](distance),
		}),
		'out-up': (distance?: Distance) => ({
			transition: TRANSITION.quick,
			...MOTION_IN['right'](distance),
			...MOTION_OUT['up'](distance),
		}),
		'out-down': (distance?: Distance) => ({
			transition: TRANSITION.quick,
			...MOTION_IN['right'](distance),
			...MOTION_OUT['down'](distance),
		}),
	},
	'in-up': {
		fade: (distance?: Distance) => ({
			transition: TRANSITION.quick,
			...MOTION_IN['up'](distance),
			...MOTION_OUT['fade'](),
		}),
		'out-right': (distance?: Distance) => ({
			transition: TRANSITION.quick,
			...MOTION_IN['up'](distance),
			...MOTION_OUT['right'](distance),
		}),
		'out-left': (distance?: Distance) => ({
			transition: TRANSITION.quick,
			...MOTION_IN['up'](distance),
			...MOTION_OUT['left'](distance),
		}),
		'out-up': (distance?: Distance) => ({
			transition: TRANSITION.quick,
			...MOTION_IN['up'](distance),
			...MOTION_OUT['up'](distance),
		}),
		'out-down': (distance?: Distance) => ({
			transition: TRANSITION.quick,
			...MOTION_IN['up'](distance),
			...MOTION_OUT['down'](distance),
		}),
	},
	'in-down': {
		fade: (distance?: Distance) => ({
			transition: TRANSITION.quick,
			...MOTION_IN['down'](distance),
			...MOTION_OUT['fade'](),
		}),
		'out-right': (distance?: Distance) => ({
			transition: TRANSITION.quick,
			...MOTION_IN['down'](distance),
			...MOTION_OUT['right'](distance),
		}),
		'out-left': (distance?: Distance) => ({
			transition: TRANSITION.quick,
			...MOTION_IN['down'](distance),
			...MOTION_OUT['left'](distance),
		}),
		'out-up': (distance?: Distance) => ({
			transition: TRANSITION.quick,
			...MOTION_IN['down'](distance),
			...MOTION_OUT['up'](distance),
		}),
		'out-down': (distance?: Distance) => ({
			transition: TRANSITION.quick,
			...MOTION_IN['down'](distance),
			...MOTION_OUT['down'](distance),
		}),
	},
};
