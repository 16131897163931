/**
 * This file is automatically generated. Do not edit it directly.
 * Read docs/generating-variables.md for more information on how to add a new variable.
 */

export const variables = {
	'--font-size-10': '10px',
	'--font-size-12': '12px',
	'--font-size-14': '14px',
	'--font-size-16': '16px',
	'--font-size-18': '18px',
	'--font-size-20': '20px',
	'--font-size-24': '24px',
	'--font-size-32': 'clamp(24px, 3vw, 32px)',
	'--font-size-34': '34px',
	'--font-size-40': '40px',
	'--font-size-56': '56px',
	'--font-size-72': 'clamp(44px, 5vw, 72px)',
	'--font-weight-regular': '400',
	'--font-weight-semi-bold': '600',
	'--font-weight-extra-bold': '800',
	'--font-family': 'var(--font-raleway)',
	'--line-height-140': '1.4',
	'--line-height-150': '1.5',
	'--line-height-170': '1.7',
	'--letter-spacing-default': '0.02em',
	'--letter-spacing-increased': '0.15em',
	'--letter-spacing-decreased': '-0.03em',
	'--border-radius-xsmall': '1px',
	'--border-radius-small': '2px',
	'--border-radius-medium': '4px',
	'--border-radius-large': '8px',
	'--border-radius-xlarge': '16px',
	'--border-radius-round': '10000px',
	'--backdrop-filter-small': 'blur(5px)',
	'--backdrop-filter-medium': 'blur(10px)',
	'--backdrop-filter-large': 'blur(45px)',
	'--transition-duration-instant': '0.075s',
	'--transition-duration-quick': '0.12s',
	'--transition-duration-base': '0.3s',
	'--transition-duration-smooth': '0.6s',
	'--transition-duration-long': '1.2s',
	'--transition-timing-function-ease-in-out': 'cubic-bezier(0.42, 0, 0.57, 1)',
	'--transition-timing-function-fast-out-slow-in': 'cubic-bezier(0.4, 0, 0.2, 1)',
	'--transition-instant': '0.075s cubic-bezier(0.42, 0, 0.57, 1)',
	'--transition-quick': '0.12s cubic-bezier(0.42, 0, 0.57, 1)',
	'--transition-base': '0.3s cubic-bezier(0.42, 0, 0.57, 1)',
	'--transition-smooth': '0.6s cubic-bezier(0.42, 0, 0.57, 1)',
	'--transition-long': '1.2s cubic-bezier(0.42, 0, 0.57, 1)',
	'--filter-focus': 'brightness(1.2) saturate(0.75)',
	'--filter-active': 'brightness(0.9) saturate(0.75)',
	'--border-width-2': '2px',
	'--border-width-xsmall': '1px',
	'--border-width-small': '3px',
	'--border-width-medium': '6px',
	'--box-shadow-small': '0px 3px 5px 0px rgba(0, 0, 0, 0.2)',
	'--box-shadow-medium': '0px 4px 16px 0px rgba(0, 0, 0, 0.1)',
	'--box-shadow-large': '0px 7px 50px 0px rgba(0, 0, 0, 0.2), 0px 3px 5px 0px rgba(0, 0, 0, 0.2)',
	'--color-primary': '#002039',
	'--color-on-primary': '#ffffff',
	'--color-secondary': '#00aace',
	'--color-on-secondary': '#ffffff',
	'--color-state-disabled': '#464646',
	'--color-state-error': '#7b0b0b',
	'--color-loading-state': 'rgba(0, 32, 57, 0.03)',
	'--color-loading-hightlight': 'rgba(255, 255, 255, 0.25)',
	'--color-neutral-10': '#3a3a3a',
	'--color-neutral-11': '#333333',
	'--color-neutral-12': '#2d2d2d',
	'--color-neutral-13': '#262626',
	'--color-neutral-14': '#202020',
	'--color-neutral-15': '#191919',
	'--color-neutral-16': '#131313',
	'--color-neutral-17': '#0d0d0d',
	'--color-neutral-18': '#060606',
	'--color-neutral-01': '#f3f3f2',
	'--color-neutral-02': '#e6e6e5',
	'--color-neutral-03': '#dadad7',
	'--color-neutral-04': '#cdcdca',
	'--color-neutral-05': '#c1c0bc',
	'--color-neutral-06': '#a7a7a2',
	'--color-neutral-07': '#8e8d87',
	'--color-neutral-08': '#75746c',
	'--color-neutral-09': '#5e5d56',
	'--color-neutral-black': '#000000',
	'--color-neutral-black-25': '#00000040',
	'--color-neutral-white': '#ffffff',
	'--color-neutral-opacity10': 'rgba(255, 255, 255, 0.1)',
	'--color-neutral-opacity20': 'rgba(255, 255, 255, 0.2)',
	'--color-accent-yellow-base': '#f5ce00',
	'--color-dark-blue-10': '#0020391A',
	'--color-dark-blue-75': '#002039bf',
	'--color-dark-blue-base': '#002039',
	'--color-dark-blue-middle-blue10': '#002E48',
	'--color-dark-blue-middle-blue20': '#003C57',
	'--color-dark-blue-white25': '#40586B',
	'--color-dark-blue-white50': '#808F9C',
	'--color-dark-blue-white75': '#BFC7CD',
	'--color-dark-blue-white90': '#E5E9EB',
	'--color-dark-blue-white95': '#F2F4F5',
	'--color-dark-blue-white97': '#F7F8F9',
	'--color-middle-blue-base': '#00aace',
	'--color-middle-blue-dark-blue20': '#008EB0',
	'--color-light-blue-base': '#cee9ef',
	'--color-light-blue-white50': '#E6F4F7',
	'--color-light-blue-white75': '#F3FAFB',
	'--color-sand-base': '#b4a793',
	'--color-sand-white75': '#ECE9E4',
	'--color-sand-white90': '#F8F6F4',
	'--color-signal-red': '#bf2626',
	'--color-signal-red70': '#F5DFDF',
	'--color-signal-yellow': '#f5ce00',
	'--color-signal-yellow70': '#FCF0B3',
	'--color-signal-green': '#3a853d',
	'--color-signal-green70': '#E1EDE2',
	'--color-illustration-sand': '#b4a793',
	'--color-illustration-blue': '#8ac7d4',
	'--color-illustration-green': '#c4dac5',
	'--color-illustration-orange': '#e6b9a0',
	'--color-illustration-red': '#d4b6b6',
	'--color-illustration-grey': '#bcc1c5',
	'--color-linear-gradient1': 'linear-gradient(0deg, rgba(0, 170, 206, 0.25), rgba(0, 170, 206, 0.25)), rgba(0, 32, 57, 0.75);',
	'--color-linear-gradient2': 'linear-gradient(180deg, rgba(0, 0, 0, 0%) 0%, rgba(0, 0, 0, 25%) 100%)',
	'--color-linear-gradient3': 'linear-gradient(0deg, rgba(0, 170, 206, 0.25), rgba(0, 170, 206, 0.25)), rgba(0, 32, 57, 0.25)',
	'--color-scroll-shadow-gradient-left': 'linear-gradient(270deg, rgba(0, 32, 57, 0) 0%, rgba(0, 32, 57, 100%) 50%);',
	'--color-scroll-shadow-gradient-right': 'linear-gradient(90deg, rgba(0, 32, 57, 0) 0%, rgba(0, 32, 57, 100%) 50%);',
	'--color-content-drawer-overlay': 'rgba(0, 0, 0, 25%)',
	'--palette-primary': '#ffffff',
	'--palette-secondary': '#002039',
	'--palette-tertiary': '#00aace',
	'--palette-darkblue-primary': '#002039',
	'--palette-darkblue-secondary': '#ffffff',
	'--palette-darkblue-tertiary': '#00aace',
	'--palette-middleblue-primary': '#00aace',
	'--palette-middleblue-secondary': '#ffffff',
	'--palette-middleblue-tertiary': '#002039',
	'--palette-white-primary': '#ffffff',
	'--palette-white-secondary': '#002039',
	'--palette-white-tertiary': '#00aace',
	'--palette-lightblue-primary': '#E6F4F7',
	'--palette-lightblue-secondary': '#002039',
	'--palette-lightblue-tertiary': '#00aace',
	'--palette-sand-primary': '#ECE9E4',
	'--palette-sand-secondary': '#002039',
	'--palette-sand-tertiary': '#00aace',
	'--palette-lightsand-primary': '#F8F6F4',
	'--palette-lightsand-secondary': '#002039',
	'--palette-lightsand-tertiary': '#00aace',
	'--palette-lightgrey-primary': '#F2F4F5',
	'--palette-lightgrey-secondary': '#002039',
	'--palette-lightgrey-tertiary': '#00aace',
	'--z-index-background': -1,
	'--z-index-content': 0,
	'--z-index-content-highlighted': 10,
	'--z-index-header': 20,
	'--z-index-drawer': 30,
	'--z-index-overlay': 40,
	'--size-1': 'calc(4px * 1)',
	'--size-2': 'calc(4px * 2)',
	'--size-3': 'calc(4px * 3)',
	'--size-4': 'calc(4px * 4)',
	'--size-5': 'calc(4px * 5)',
	'--size-6': 'calc(4px * 6)',
	'--size-7': 'calc(4px * 7)',
	'--size-8': 'calc(4px * 8)',
	'--size-9': 'calc(4px * 9)',
	'--size-10': 'calc(4px * 10)',
	'--size-11': 'calc(4px * 11)',
	'--size-12': 'calc(4px * 12)',
	'--size-13': 'calc(4px * 13)',
	'--size-14': 'calc(4px * 14)',
	'--size-15': 'calc(4px * 15)',
	'--size-16': 'calc(4px * 16)',
	'--size-17': 'calc(4px * 17)',
	'--size-18': 'calc(4px * 18)',
	'--size-19': 'calc(4px * 19)',
	'--size-20': 'calc(4px * 20)',
	'--size-21': 'calc(4px * 21)',
	'--size-22': 'calc(4px * 22)',
	'--size-23': 'calc(4px * 23)',
	'--size-24': 'calc(4px * 24)',
	'--size-25': 'calc(4px * 25)',
	'--size-26': 'calc(4px * 26)',
	'--size-27': 'calc(4px * 27)',
	'--size-28': 'calc(4px * 28)',
	'--size-29': 'calc(4px * 29)',
	'--size-30': 'calc(4px * 30)',
	'--size-32': 'calc(4px * 32)',
	'--size-35': 'calc(4px * 35)',
	'--size-scale': '4px',
	'--size-05': 'calc(4px * 0.5)',
	'--size-header': 'var(--size-16)',
	'--size-header-md': 'var(--size-18)',
	'--size-breadcrumb': 'var(--size-12)',
	'--size-fluid-tiny': 'clamp(calc(4px * 1), 1.25vw, calc(4px * 2))',
	'--size-fluid-xsmall': 'clamp(calc(4px * 2), 2vw, calc(4px * 4))',
	'--size-fluid-small': 'clamp(calc(4px * 3), 3vw, calc(4px * 6))',
	'--size-fluid-medium': 'clamp(calc(4px * 4), 3.5vw, calc(4px * 8))',
	'--size-fluid-large': 'clamp(calc(4px * 6), 5vw, calc(4px * 12))',
	'--size-fluid-xlarge': 'clamp(calc(4px * 8), 6vw, calc(4px * 16))',
	'--size-fluid-huge': 'clamp(calc(4px * 12), 8vw, calc(4px * 20))',
} as const;
