export enum BackgroundColor {
	White = 'white',
	Darkblue = 'darkblue',
}

export enum IllustrationColor {
	Sand = 'sand',
	Blue = 'blue',
	Green = 'green',
	Orange = 'orange',
	Red = 'red',
	Grey = 'grey',
}

export enum MosaicTheme {
	Lightblue = 'lightblue',
	Middleblue = 'middleblue',
	Darkblue = 'darkblue',
	Sand = 'sand',
}

export enum ColorTheme {
	None = 'noColor',
	Custom = 'custom',
	Darkblue = 'darkblue',
	Middleblue = 'middleblue',
	Lightblue = 'lightblue',
	Sand = 'sand',
	Lightsand = 'lightsand',
	Lightgrey = 'lightgrey',
}

export type ThemeDefinition = {
	theme: ColorTheme;
	primary?: string | null;
	secondary?: string | null;
	tertiary?: string | null;
};
