import Script from 'next/script';

export function SiteImproveScript() {
	return (
		<Script async src="https://siteimproveanalytics.com/js/siteanalyze_6290685.js" onLoad={onSiteimproveLoad} />
	);
}

function onSiteimproveLoad() {
	// Ensure feedback popup is hidden
	document.cookie = 'sz-feedback-should-hide=true';
}
