import Script from 'next/script';

export function CookieInformationScript() {
	return (
		<Script
			strategy="afterInteractive"
			id="CookieConsent"
			src="https://policy.app.cookieinformation.com/uc.js"
			data-culture="DA"
			type="text/javascript"
			data-gcm-version="2.0"
		/>
	);
}
