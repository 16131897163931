/**
 * This file is automatically generated. Do not edit it directly.
 * Read docs/generating-variables.md for more information on how to add a new variable.
 */

import { variables as cssVariables } from './variables.web';

export enum Palette {
	lightgrey = 'lightgrey',
	lightsand = 'lightsand',
	sand = 'sand',
	lightblue = 'lightblue',
	white = 'white',
	middleblue = 'middleblue',
	darkblue = 'darkblue',
}

export const paletteKeys = [
	Palette.lightgrey,
	Palette.lightsand,
	Palette.sand,
	Palette.lightblue,
	Palette.white,
	Palette.middleblue,
	Palette.darkblue,
] as const;

export type PaletteKey = typeof paletteKeys[number];

export const paletteMap = {
	lightgrey: {
		tertiary: cssVariables['--palette-lightgrey-tertiary'],
		secondary: cssVariables['--palette-lightgrey-secondary'],
		primary: cssVariables['--palette-lightgrey-primary'],
	},
	lightsand: {
		tertiary: cssVariables['--palette-lightsand-tertiary'],
		secondary: cssVariables['--palette-lightsand-secondary'],
		primary: cssVariables['--palette-lightsand-primary'],
	},
	sand: {
		tertiary: cssVariables['--palette-sand-tertiary'],
		secondary: cssVariables['--palette-sand-secondary'],
		primary: cssVariables['--palette-sand-primary'],
	},
	lightblue: {
		tertiary: cssVariables['--palette-lightblue-tertiary'],
		secondary: cssVariables['--palette-lightblue-secondary'],
		primary: cssVariables['--palette-lightblue-primary'],
	},
	white: {
		tertiary: cssVariables['--palette-white-tertiary'],
		secondary: cssVariables['--palette-white-secondary'],
		primary: cssVariables['--palette-white-primary'],
	},
	middleblue: {
		tertiary: cssVariables['--palette-middleblue-tertiary'],
		secondary: cssVariables['--palette-middleblue-secondary'],
		primary: cssVariables['--palette-middleblue-primary'],
	},
	darkblue: {
		tertiary: cssVariables['--palette-darkblue-tertiary'],
		secondary: cssVariables['--palette-darkblue-secondary'],
		primary: cssVariables['--palette-darkblue-primary'],
	},
} as const;

export const paletteVariableMap = {
	lightgrey: {
		tertiary: '--palette-lightgrey-tertiary',
		secondary: '--palette-lightgrey-secondary',
		primary: '--palette-lightgrey-primary',
	},
	lightsand: {
		tertiary: '--palette-lightsand-tertiary',
		secondary: '--palette-lightsand-secondary',
		primary: '--palette-lightsand-primary',
	},
	sand: {
		tertiary: '--palette-sand-tertiary',
		secondary: '--palette-sand-secondary',
		primary: '--palette-sand-primary',
	},
	lightblue: {
		tertiary: '--palette-lightblue-tertiary',
		secondary: '--palette-lightblue-secondary',
		primary: '--palette-lightblue-primary',
	},
	white: {
		tertiary: '--palette-white-tertiary',
		secondary: '--palette-white-secondary',
		primary: '--palette-white-primary',
	},
	middleblue: {
		tertiary: '--palette-middleblue-tertiary',
		secondary: '--palette-middleblue-secondary',
		primary: '--palette-middleblue-primary',
	},
	darkblue: {
		tertiary: '--palette-darkblue-tertiary',
		secondary: '--palette-darkblue-secondary',
		primary: '--palette-darkblue-primary',
	},
} as const;
