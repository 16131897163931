import { useEffect, useState } from 'react';

const ConsentKeys = {
	necessary: 'cookie_cat_necessary',
	functional: 'cookie_cat_functional',
	statistic: 'cookie_cat_statistic',
	marketing: 'cookie_cat_marketing',
	unclassified: 'cookie_cat_unclassified',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ConsentKeys = (typeof ConsentKeys)[keyof typeof ConsentKeys];

export const ConsentKeysArray = Object.values(ConsentKeys);

export interface CWindow extends Window {
	CookieInformation?: {
		getConsentGivenFor: (key: ConsentKeys) => boolean;
		loadConsent: () => void;
	} | undefined;
}

type Consent = {
	[key in keyof typeof ConsentKeys]: boolean | null;
};

export function getConsentFromCookieInformation(): Consent {
	const CookieInformation = (window as unknown as CWindow).CookieInformation;

	return {
		necessary: CookieInformation?.getConsentGivenFor(ConsentKeys.necessary) || false,
		functional: CookieInformation?.getConsentGivenFor(ConsentKeys.functional) || false,
		statistic: CookieInformation?.getConsentGivenFor(ConsentKeys.statistic) || false,
		marketing: CookieInformation?.getConsentGivenFor(ConsentKeys.marketing) || false,
		unclassified: CookieInformation?.getConsentGivenFor(ConsentKeys.unclassified) || false,
	};
}

export function useCookieconsent() {
	const [consent, setConsent] = useState<Consent>({
		necessary: null,
		functional: null,
		statistic: null,
		marketing: null,
		unclassified: null,
	});

	useEffect(() => {
		function handleConsentUpdate() {
			const consentStatus = getConsentFromCookieInformation();
			setConsent(consentStatus);
		}

		handleConsentUpdate();

		window.addEventListener('CookieInformationConsentGiven', handleConsentUpdate, false);

		return () => {
			window.removeEventListener('CookieInformationConsentGiven', handleConsentUpdate);
		};
	}, []);

	return consent;
}

export async function onConsentReady() {
	return new Promise<void>((resolve) => {
		const consentGiven = (window as Window as CWindow).CookieInformation?.getConsentGivenFor('cookie_cat_marketing');
		if (typeof consentGiven !== 'undefined') {
			resolve();
		}

		window.addEventListener('CookieInformationConsentGiven', () => {
			resolve();
		});
	});
}
