import { ReactNode } from 'react';
import cn from 'classnames';

import styles from './portal_provider.module.scss';

type Props = { children: ReactNode; scrollable?: boolean };

const id = 'app_portal';

export const PortalProvider = ({ children, scrollable }: Props) => {
	return (
		<>
			{children}
			<div id={id} className={cn(styles['portal'], { [styles.portal__scrollable]: scrollable })} />
		</>
	);
};

PortalProvider.id = id;
