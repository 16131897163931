import { DependencyList, useCallback, useEffect, useRef } from 'react';
import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';
import { Param1, Param2 } from 'type-zoo/types';

export const useOnClickOutsideEffect = <TElement extends HTMLElement = HTMLElement>(
	ref: React.RefObject<TElement>,
	onClickOutside: (event: MouseEvent) => void,
	condition?: boolean,
) => {
	const closeOnClickOutside: EventListenerCallback<'click'> = useCallback(
		(e) => {
			if (!ref.current || !e.target || condition !== true) return;

			if (!(ref.current as HTMLElement)?.contains(e.target as HTMLElement)) {
				onClickOutside(e);
			}
		},
		[onClickOutside, condition],
	);

	useEffect(() => {
		if (condition !== true) return;

		window.addEventListener('click', closeOnClickOutside);

		return () => window.removeEventListener('click', closeOnClickOutside);
	}, [condition, closeOnClickOutside]);
};

export const useOnClickOutside = <TElement extends HTMLElement = HTMLElement>(
	onClickOutside: (event: MouseEvent) => void,
	condition?: boolean,
) => {
	const ref = useRef<TElement>(null);

	useOnClickOutsideEffect(ref, onClickOutside, condition);

	return ref;
};

type EventListenerCallback<K extends keyof WindowEventMap> = (ev: WindowEventMap[K]) => void;

/**
 * A wrapper for the EventListener
 */
export const useKeyPressEffect = (
	/**
	 * The Target Key or `debug` which will output in the console the triggered event.
	 */
	targetKey: string | 'debug',
	/**
	 * The callback to be executed when the targetKey is pressed
	 */
	callback: Param1<typeof useEventListener>,
	/**
	 * Dependencies for the callback
	 * If you want the effect to be re-run when the dependencies change,
	 */
	config?: Param2<typeof useEventListener>,
) => {
	const handleCallback: EventListenerCallback<'keydown'> = (e) => {
		if (targetKey === 'debug') {
			// eslint-disable-next-line no-console
			console.log('Key:', e.key, e);
		}

		if (e.key === targetKey) {
			callback?.(e);
		}
	};

	useEventListener('keydown', handleCallback, config);
};

/**
 * A wrapper for the EventListener
 */
export function useEventListener<K extends keyof WindowEventMap>(
	/**
	 * The Event Listener type
	 */
	type: K,
	/**
	 * The callback to be executed when the type is triggered
	 */
	callback: ((ev: WindowEventMap[K]) => void) | undefined,
	/**
	 * Configuration for the Event Listener
	 */
	config: {
		/**
		 * Dependencies for the callback
		 * If you want the effect to be re-run when the dependencies change,
		 */
		deps?: DependencyList | never[] | undefined;
		debounce?: number;
		throttle?: number;
	} = {}) {
	/**
	 * The event listener do no matter what require a callback, so we're giving it one,
	 * event if the callback is undefined. This makes it possible to disable the event listener temporarily.
	 */
	const handleCallback: EventListenerCallback<K> = (e) => callback?.(e);

	/**
	 * We then create a listener based on the configuration and the callback
	 * This makes it possible to pass the event listener a throttled or debounced callback
	 */
	let listener: EventListenerCallback<K>;

	if (config?.debounce) {
		listener = debounce(handleCallback, config.debounce);
	} else if (config?.throttle) {
		listener = throttle(handleCallback, config.throttle);
	} else {
		listener = handleCallback;
	}

	useEffect(() => {
		// Add the event listener
		window.addEventListener(type, listener);

		// If no callback exists, then just remove the event listener again.
		if (!callback) {
			window.removeEventListener(type, listener);
		}

		// Remove event listeners on cleanup
		return () => {
			window.removeEventListener(type, listener);
		};
	}, config?.deps || []); // Empty array ensures that effect is only run on mount and unmount
}
