import React from 'react';
import classnames from 'classnames';

import { cssHelpers } from '@dansk-metal/theme';
import { MediaQuery } from '@dansk-metal/ui';
import { MOTION_IN, MOTION_OUT } from '@dansk-metal/utils/animations';

import { Button, ButtonProps } from '../../button';
import { Portal, PortalProps, UsePortalConfig } from '../../portal';

import styles from './dialog.module.scss';

const { getVariants } = cssHelpers(styles);

export type DialogProps = {
	children: React.ReactNode;
	classNames?: Partial<Record<'close', string>>;
	size?: 'small' | 'large' | 'xlarge';
	scrollable?: boolean;
	altOverlay?: boolean;
} & PortalProps;

export const Dialog = ({
	children,
	handleClose,
	className,
	classNames,
	size = 'large',
	scrollable = false,
	altOverlay = false,
	...rest
}: DialogProps) => {
	const isLargerThanBPM = MediaQuery.useMediaQuery({ minWidth: styles['bp-m'] });
	const sizeVariant = getVariants('size', size);

	return (
		<Portal
			className={classnames(styles.dialog, sizeVariant, { [styles.dialog__scrollable]: scrollable }, className)}
			altOverlay={altOverlay}
			handleClose={handleClose}
			renderBefore={<Portal.Overlay handleClose={handleClose}
			/>}
			animation={
				isLargerThanBPM
					? {
						...MOTION_IN['up']('5%'),
						...MOTION_OUT['down']('5%'),
					}
					: {
						...MOTION_IN['up']('60%'),
						...MOTION_OUT['down']('100%'),
					}
			}
			{...rest}
		>
			<Button
				icon={{ icon: 'dismiss' }}
				className={classnames(styles.close, classNames?.close)}
				variant="plain"
				aria-label="Close the dialog"
				onClick={handleClose}
			/>
			{children}
		</Portal>
	);
};

export const useDialog = (options?: Partial<UsePortalConfig>) =>
	Portal.usePortal({ closeOnClickOutside: false, ...options });

Dialog.useDialog = useDialog;

const Actions = ({
	buttons,
}: {
	buttons: (ButtonProps & { priority?: 'medium' | 'high'; id: string | number })[];
}) => {
	if (buttons?.length === 0) return null;

	return (
		<div className={styles.actions}>
			{buttons.map(({ id, priority, children, title, ...button }) => (
				<Button
					key={id}
					variant={priority === 'high' ? 'fill-primary' : 'stroke'}
					size="medium"
					title={title}
					{...button}
				>
					{children || title}
				</Button>
			))}
		</div>
	);
};

Dialog.Actions = Actions;
