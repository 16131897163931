import config from '@web/config';

import { conversionId, EventTypes } from '@web/services/google-ads/config';

type DataLayerItem<O extends Record<string, string | number> = {}> = ['event', 'conversion', { send_to: string } & O];

declare global {
	interface Window {
		dataLayer?: DataLayerItem[];
		gtag: (...args: DataLayerItem) => void;
	}
}

/**
 * @returns The `dataLayer` array.
 */
function getDataLayer() {
	return (typeof window !== 'undefined' && window.dataLayer) || [];
}

/**
 * @returns Whether the `dataLayer` array is initialized and available.
 */
function isInitialized() {
	const dl = getDataLayer();

	return dl.length > 0;
}

/**
 * Track an convertion event
 *
 * @example
 * ```tsx
 * <button onClick={() => trackConvertion(EventTypes.Signup)}>Text</button>
 * ```
 */
function trackConversion(eventType: EventTypes) {
	if (!isInitialized() || !window.gtag) {
		throw new Error('GTM not initialized');
	}

	window.gtag('event', 'conversion', { send_to: `${config.googleAds.containerId}/${conversionId(eventType)}` });
}

export const googleAds = {
	trackConversion,
	getDataLayer,
	isInitialized,
};
