import React, { forwardRef } from 'react';
import cn from 'classnames';

import styles from './container.module.scss';

export type ContainerProps = {
	children: React.ReactNode;
	className?: string;
};

export const Container = forwardRef<HTMLDivElement, ContainerProps>((props, ref) => {
	const { className, children, ...rest } = props;

	return (
		<div className={cn(styles.container, className)} ref={ref} {...rest}>
			{children}
		</div>
	);
});
