const BASE_URL =
	process.env.BASE_URL ||
	process.env.NEXT_PUBLIC_BASE_URL ||
	(process.env.NEXT_PUBLIC_VERCEL_URL ? `https://${process.env.NEXT_PUBLIC_VERCEL_URL}` : false) ||
	'http://localhost:4200';

const Config = {
	BASE_URL,
	APP_ENV: process.env.NEXT_PUBLIC_APP_ENV || 'local',
	UMBRACO_URL: process.env.NEXT_PUBLIC_UMBRACO_URL
		|| process.env.STORYBOOK_UMBRACO_URL
		|| 'https://localhost:44321',
	API_MOCKING: process.env.NEXT_PUBLIC_API_MOCKING === 'enabled' || false,

	PREVIEW_SECRET: process.env.PREVIEW_SECRET,
	WEBHOOK_SECRET: process.env.WEBHOOK_SECRET,

	VERCEL_REDEPLOY_URL: process.env.NEXT_PUBLIC_VERCEL_REDEPLOY_URL,
	RAFFLE: {
		url: process.env.NEXT_PUBLIC_RAFFLE_URL || 'https://api.raffle.ai/v1',
		uid: process.env.NEXT_PUBLIC_RAFFLE_UID || '160620f4-7e3d-4f4c-88f6-705b65aefc0b',
		sessionId: process.env.NEXT_PUBLIC_RAFFLE_SESSION_ID || 'raffle-sdk',
	},
	QBANK: {
		url: process.env.NEXT_PUBLIC_QBANK_URL || 'https://powered-by.qbank.se/dm-umbraco',
		apiKey: process.env.UMBRACO_FILE_QBANK_API_KEY || 'Test123',
	},
	AUTH: {
		url: process.env.AUTH_URL,
		clientId: process.env.AUTH_CLIENT_ID,
		clientSecret: process.env.AUTH_CLIENT_SECRET,
	},
	CRM: {
		url: process.env.NEXT_PUBLIC_CRM_URL,
		apiSecret: process.env.CRM_API_SECRET,
		audience: process.env.CRM_AUDIENCE,
		clientId: process.env.CRM_CLIENT_ID,
		clientSecret: process.env.CRM_CLIENT_SECRET,
	},
	googleAds: {
		enabled: process.env.NEXT_PUBLIC_GOOGLE_ADS_ENABLED === 'enabled',
		containerId: process.env.NEXT_PUBLIC_GOOGLE_ADS_CONTAINER_ID,
	},
	google: {
		tagManager: {
			id: 'GTM-5DGSCH9X',
		},
	},
};

export default Config;
