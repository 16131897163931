import React from 'react';
import classNames from 'classnames';
import isObject from 'lodash/isObject';

import { Icons, icons } from '../icons';

import styles from './icon.module.scss';

export type IconProps = React.SVGProps<SVGSVGElement> & {
	/**
	 * @returns rotate({x}deg)
	 */
	rotate?: number;
	/**
	 * @returns scale({x})
	 */
	scale?: number;
	/**
	 * @returns translateX({x})
	 */
	x?: number | string;
	/**
	 * @returns translateY({x})
	 */
	y?: number | string;
	/**
	 * @returns
	 */
	size?: number | string | { width: number | string; height: number | string } | null;
	color?: string;
	icon: Icons;
};

export const Icon = ({
	icon,
	x,
	y,
	rotate,
	scale,
	className,
	color,
	size = 16,
	style,
	...rest
}: IconProps) => {
	const Component = icon ? icons[icon] : undefined;

	if (icon && !icons[icon]) {
		console.warn(`Icon "${icon}" not found`);
		return null;
	}

	const transform = [
		scale && `scale(${scale})`,
		x && `translateX(${typeof x === 'number' ? `${x}px` : x})`,
		y && `translateY(${typeof y === 'number' ? `${y}px` : y})`,
		rotate && `rotate(${rotate}deg)`,
	]
		.filter((i) => !!i)
		.join(' ');

	const collectedStyles = {
		...style,
		'--icon-color': color,
		...(transform && { transform }),
		...(size !== null && isObject(size)
			? { width: size.width, height: size.height }
			: { width: size, height: size }),
	} as React.CSSProperties;

	return (
		<Component
			data-icon={icon}
			className={classNames(styles.icon, className)}
			style={collectedStyles}
			{...rest}
		/>
	);
};
