import { BackgroundColor, ColorTheme, MosaicTheme } from '@apps/web/src/services/umbraco/types/basic/Color';

import { Palette, paletteKeys } from '../../__GENERATED__/palettes';
export * from '../../__GENERATED__/palettes';

type Color = ColorTheme | BackgroundColor | MosaicTheme;

export function isValidPalette(value: string): value is Palette {
	return paletteKeys.includes(value as Palette);
}

export function getPallette(color?: Color) {
	return color && isValidPalette(color) ? color : undefined;
}

export function hasColorPallette(color?: Color): boolean {
	return !!color && ((isValidPalette(color) && ColorTheme.None !== color) || ColorTheme.Custom === color);
}
