export const EventTypes = {
	/** User clicks signup on membership page */
	Signup: 'Signup',
	/** User clicks login button */
	Login: 'Login',
	/** User completes Salary calculator */
	SalaryCalculator: 'SalaryCalculator',
	/** User completes apprentice salary calculator */
	ApprenticeSalaryCalculator: 'ApprenticeSalaryCalculator',
} as const;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type EventTypes = typeof EventTypes[keyof typeof EventTypes];

const idConfig = {
	Signup: 'OQgXCKqTwvQYEPjBzJsD',
	Login: 'S9ccCKOdkfIYEPjBzJsD',
	SalaryCalculator: 'yQo9CI_ExfQYEPjBzJsD',
	ApprenticeSalaryCalculator: 'IerfCOiakfIYEPjBzJsD',
};

export function conversionId(event: EventTypes): string {
	return idConfig[event];
}
