import { entries } from '@dansk-metal/utils/common';

import { variables as generatedVariables } from '../../__GENERATED__/variables.web';
import type { Theme } from '../types/theme';

export const variables: Theme['variables'] = { ...generatedVariables };

/**
 * @returns The CSS variable
 *
 * The available CSS variables from the `theme/src/lib/variables.scss` file.
 *
 *  @see {@link variables}
 *
 * @example
 * ```tsx
 * const black = variablesValue['--color-primary']; // var(--color-primary);
 * ```
 */
export const variablesVars = entries(variables).reduce((vars, [key]) => {
	return { ...vars, [key]: `var(${key})` };
}, {} as Record<keyof Theme['variables'], `var(${keyof Theme['variables']})`>);

export const vars = variablesVars;

/**
 * Get the CSS variable name for a given `variable` key
 * If multiple `variables` are passed, it will return a space separated string (e.g. `var(1) var(2)`)
 *
 * @returns
 * - The CSS `variable` value
 * - If an `array` is passed, it will return a space separated string (e.g. `var(1) var(2)`)
 * - If the `variable` is not found, it will return the `fallback` value - default `0`
 *
 * @example
 * getCSSVariable('--color-primary'); // var(--color-primary);
 * getCSSVariable(['--size-3', '--size-5']); // var(--size-3) var(--size-5);
 * getCSSVariable('--none-existing-variable', 'red'); // red;
 * getCSSVariable([0, '--size-5']); // 0 var(--size-5);
 * getCSSVariable([null, '--size-5']); // 0 var(--size-5);
 * getCSSVariable([null, '--size-5'], 'var(--size-2)'); // var(--size-2) var(--size-5);
 */
export const getCSSVariable = (
	_vars: keyof Theme['variables'] | null | 0 | (keyof Theme['variables'] | null | 0)[],
	fallback: string | number | undefined = 0,
) => {
	if (Array.isArray(_vars)) {
		return _vars.map((v) => (v && variablesVars[v]) || (fallback ?? '')).join(' ');
	}

	return (_vars && variablesVars[_vars]) || (fallback ?? '');
};
