import React, { AnchorHTMLAttributes, ButtonHTMLAttributes, forwardRef } from 'react';
import Link, { LinkProps } from 'next/link';
import classnames from 'classnames';
import omit from 'lodash/omit';
import { ExtractStrict, OmitStrict } from 'type-zoo';

import { cssHelpers, Theme } from '@dansk-metal/theme';
import { ExtendableForwardRef } from '@dansk-metal/utils/common';

import { Icon, IconProps } from '../../icon';

import styles from './button.module.scss';

const { getVariants } = cssHelpers(styles);

type ButtonBaseProps = {
	children?: React.ReactNode;
	size?: ExtractStrict<Theme['size'], 'medium' | 'large'>;
	isLoading?: boolean;
	variant?: 'plain' | 'inline' | 'inline-thick' | `fill-${'primary' | 'secondary'}` | 'stroke';
	icon?: IconProps & {
		position?: Theme['position']['order'];
		justifyContentSpaceBetween?: boolean;
	};
	/**
	 * Unstyled removes all `button` styling and adds "reset" styling
	 * to make the `button` and `a` tags look identical (padding, margin, appearance, etc.)
	 * This is useful if you want the logic of the `button` but not the styling.
	 */
	unstyled?: boolean;
	/**
	 * Internal use for `Link` component.
	 * This is being controled by the `href` attribute and then chose the semantic correct HTML tag
	 */
	tag?: ExtractStrict<keyof JSX.IntrinsicElements, 'a' | 'button'>;
} & ButtonHTMLAttributes<HTMLButtonElement> &
	AnchorHTMLAttributes<HTMLAnchorElement>;

const ButtonBase = forwardRef(
	(
		{
			variant = 'fill-primary',
			size = 'medium',
			className,
			children,
			icon,
			isLoading,
			unstyled,
			tag = 'button',
			...rest
		}: ButtonBaseProps,
		ref,
	) => {
		const variantClassName = getVariants('variant', variant);
		const sizeClassName = getVariants('size', size);
		const iconPositionClassName = getVariants('iconPosition', icon?.position || 'after');
		const iconOnly = icon && !children;
		const Component = tag as 'button';

		return (
			<Component
				className={
					unstyled === true
						? classnames(styles.unstyled, styles.button, iconPositionClassName, className)
						: classnames(variantClassName, sizeClassName, iconPositionClassName, styles.button, className, {
							[styles.iconOnly]: iconOnly,
							[styles.isLoading]: isLoading,
							[styles.spaceBetween]: icon?.justifyContentSpaceBetween,
						})
				}
				ref={ref as React.ForwardedRef<HTMLButtonElement>}
				data-button
				{...rest}
			>
				{children}
				{icon?.icon && (
					<Icon {...omit(icon, 'justifyContentSpaceBetween')} className={icon.className} size={icon.size || 24} />
				)}
			</Component>
		);
	},
);

export type ButtonProps = OmitStrict<ButtonBaseProps, 'tag'> & Partial<Pick<ButtonBaseProps, 'tag'>> & Partial<LinkProps> & { forceExternal?: boolean };

export type ButtonWithIconProps = ExtendableForwardRef<ButtonProps, { icons?: Record<'arrow-right', ButtonProps['icon']> }>;

export const Button: ButtonWithIconProps = forwardRef((_props, ref) => {
	const { as, replace, scroll, shallow, passHref, prefetch, locale, href, tag, ...props } = _props;

	if (href) {
		return (
			<Link
				as={as}
				href={href}
				replace={replace}
				scroll={scroll}
				shallow={shallow}
				passHref={passHref}
				prefetch={prefetch}
				locale={locale}
				legacyBehavior
			>
				<ButtonBase
					tag={tag || 'a'}
					href={href}
					ref={ref}
					{...props}
				/>
			</Link>
		);
	}

	return <ButtonBase tag={tag || 'button'} ref={ref} {...props} />;
});
