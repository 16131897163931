import { SelectHTMLAttributes, useId, useState } from 'react';
import { ControllerFieldState, ControllerRenderProps } from 'react-hook-form';
import ReactSelect, { type Props as ReactSelectProps, ActionMeta, GroupBase, InputActionMeta } from 'react-select';
import { SelectComponents } from 'react-select/dist/declarations/src/components';
import classNames from 'classnames';
import { OmitStrict } from 'type-zoo';

import { Icon } from '../../../icon';
import { FieldError } from '../field-error/field-error';
import { FieldProps } from '../form/form';
import { Label, LabelProps } from '../label/label';

import styles from './select.module.scss';

const SHARED_PROPS = {
	classNamePrefix: 'react-select',
	unstyled: true,
	classNames: { container: () => styles.container },
} as ReactSelectProps;

export type SelectOption<T extends object = {}> = {
	value: string;
	label: string;
} & T;

export type SelectProps<TKey extends string, T extends SelectOption | Readonly<SelectOption>, IsMulti extends boolean = false> = {
	fieldState?: ControllerFieldState;
	options: T[] | Readonly<T[]>;
	isLoading?: boolean;
	onInputChange?: (input: string, actionMeta: InputActionMeta) => void;
	inputValue?: string;
	isMulti?: IsMulti;
	value?: IsMulti extends true
		? T[]
		: T;
	components?: Partial<SelectComponents<unknown, boolean, GroupBase<unknown>>> | undefined;
	isSearchable?: boolean;
} & LabelProps &
	OmitStrict<SelectHTMLAttributes<HTMLSelectElement>, 'form' | 'value'> &
	FieldProps<TKey> &
	Partial<OmitStrict<ControllerRenderProps, 'ref'>>;

/**
 * Select component
 *
 * @version 1.0.0
 */
export const Select = <TKey extends string, T extends SelectOption, IsMulti extends boolean = boolean>({
	className,
	description,
	fieldState,
	id,
	label,
	labelPosition = 'over',
	required,
	options,
	isMulti,
	noOptionalLabel,
	disabled,
	...rest
}: SelectProps<TKey, T, IsMulti>) => {
	return (
		<Label
			label={label}
			id={id}
			required={required}
			labelPosition={labelPosition}
			description={description}
			disabled={disabled}
			noOptionalLabel={noOptionalLabel}
			className={classNames(className, styles.selectLabel)}
		>
			{isMulti ? (
				<MultiSelect disabled={disabled} options={options} {...rest} />
			) : (
				<SingleSelect disabled={disabled} options={options} {...rest} />
			)}
			<FieldError error={fieldState?.error} />
		</Label>
	);
};

function SingleSelect<TKey extends string, T extends SelectOption>({
	options,
	value,
	onChange,
	onBlur,
	disabled,
	placeholder,
	isLoading,
	onInputChange,
	inputValue,
	components = { DropdownIndicator: () => <Icon icon="chevron-down" size="24px" /> },
	isSearchable = false,
}: SelectProps<TKey, T, false>) {
	return (
		<ReactSelect
			{...SHARED_PROPS}
			placeholder={placeholder}
			isDisabled={disabled}
			options={options}
			value={value}
			isLoading={isLoading}
			inputValue={inputValue}
			onInputChange={onInputChange}
			onChange={onChange}
			onBlur={onBlur}
			instanceId={useId()}
			noOptionsMessage={() => null}
			loadingMessage={() => null}
			components={components}
			isSearchable={isSearchable}
		/>
	);
}

function MultiSelect<TKey extends string, T extends SelectOption>({
	options,
	value,
	onChange,
	disabled,
	placeholder,
	className,
	components = { DropdownIndicator: () => <Icon icon="chevron-down" size="24px" /> },
	onBlur,
}: SelectProps<TKey, T, false>) {
	const [length, setLength] = useState(0);

	const handleChange = (values: ((newValue: unknown, actionMeta: ActionMeta<unknown>) => void) | undefined) => {
		setLength(values?.length || 0);
		onChange?.(values);
	};

	return (
		<div className={styles.wrapper}>
			{length > 1 && <div className={styles.count}>{length} options selected</div>}
			<ReactSelect
				{...SHARED_PROPS}
				placeholder={placeholder}
				options={options}
				isDisabled={disabled}
				hideSelectedOptions={false}
				closeMenuOnSelect={false}
				isClearable={false}
				value={value}
				isMulti
				onChange={handleChange}
				onBlur={onBlur}
				instanceId={useId()}
				components={components}
				className={classNames(SHARED_PROPS.classNames, className, styles.isMultipleChoice, {
					[styles.hasChosenMultiple]: length > 1,
				})}
			/>
		</div>
	);
}

const useSelect = <T, IsMulti extends boolean = boolean>(config?: {
	initialSelect?: IsMulti extends true ? T[] : T | undefined;
	isMulti?: IsMulti;
	onChange?: (option: IsMulti extends true ? T[] : T) => void;
}) => {
	const isMulti = config?.isMulti || false;

	const [selected, setSelected] = useState<IsMulti extends true ? T[] : T | undefined>(
		config?.initialSelect || ((isMulti ? [] : undefined) as IsMulti extends true ? T[] : T | undefined),
	);

	return {
		isMulti,
		value: selected,
		onChange: (option: IsMulti extends true ? T[] : T) => {
			if (config?.onChange) {
				config.onChange(option);
			}

			setSelected(option as IsMulti extends true ? T[] : T);
		},
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} as { value: IsMulti extends true ? T[] : T; isMulti: boolean; onChange: (option: any) => any }; // There is a issue with the typings inside of react-select that prevents us from using the correct types
};

Select.useSelect = useSelect;
