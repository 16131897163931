import { CSSProperties } from 'react';
import classnames from 'classnames';

import { OmitPrefix } from '@dansk-metal/utils/common';

const convertCamelToKebab = (str: string) =>
	str.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2').toLowerCase();

/**
 * This is a collection of helper functions to make it easier to work with CSS Modules.
 *
 * @see {getVariants}
 * @see {getStyle}
 */
export const cssHelpers = <Styles extends Record<string, string>>(
	/**
	 * If you're using CSS Modules, then pass the styles `object` so we can extend it
	 */
	moduleStyles: Styles,
) => {
	/**
		* Use this method if you want to create unique classNames for each variant of a component.
		* This allows you to do a lot of styling changes based on a variant.
		* It will only add the `classname` if it exists in the CSS Module.
		* Use the __DEBUG__ flag to log out the possible `classnames`.
		*
		* @return A collected `classname` for the given variants prefixed with the `prefix` property

		* @example
		* Simple Example
		* const variant: 'fill-white' | 'fill-black' | 'stroke' = props.variant;
		* getVariants('variant', variant) // "variant-fill variant-fill-white, variant-fill-black, variant-stroke"
		*
		* @example
		* TSX Example
		```tsx
				import styles from './button.module.scss';

				const { getVariants } = cssHelpers(styles); // Pass the styles from the CSS Module to the helper

				const Button = ({ variant, children }: { variant: 'fill-white' | 'fill-black' | 'stroke', children: React.ReactNode }) => {
					return (
						<button className={getVariants('variant', variant)}>{children}</button>
					)
				}
		```
		* @example
		* SCSS Example
		```scss
				.variant {
					&-fill {
						color: hotpink;

						&-white {
							background-color: white;
						}

						&-black {
							background-color: black;
						}
					}

					&-stroke {
						border: 1px solid black;
					}
				}
		* ```
		*/
	const getVariants = <
		Prefix extends string,
		Variant extends OmitPrefix<Extract<keyof Styles, string>, `${Prefix}-`>,
	>(
		/**
		 * The variant type which will be prefixed to the `classname`
		 */
		prefix: Prefix,
		/**
		 * The variants of the variant type
		 */
		variant: Variant | string | number | boolean | undefined,
		/**
		 * Log out the possible `classnames`.
		 */
		__DEBUG__?: boolean,
	) => {
		const variantAsString = variant?.toString();
		const [variantType] = variantAsString?.split('-') || [];

		if (process.env.NODE_ENV === 'development' && __DEBUG__) {
			console.group('getVariants:', prefix);

			if (typeof variant === 'boolean') {
				console.info(prefix);
			} else if (variant && variantType !== variant) {
				if (variantType) {
					console.info(`${prefix}-${variantType}`);
				}

				console.info(`${prefix}-${variant}`);
			}

			console.groupEnd();
		}

		return classnames({
			[moduleStyles[prefix]]: typeof variant === 'boolean' ? variant : variant && !!moduleStyles[prefix],
			[moduleStyles[`${prefix}-${variantType}`]]: !!variantType && !!moduleStyles[`${prefix}-${variantType}`],
			[moduleStyles[`${prefix}-${variant}`]]: !!variant && !!moduleStyles[`${prefix}-${variant}`],
		});
	};

	/**
		* Use this method if you want to pass props as CSS variables to a component
		* This allows you to utilize the variable in CSS
		* Use the __DEBUG__ flag to log out the `style` object.
		*
		* @return A collected `style` object with the given `properties` as CSS Variables.

		* @example
		* Simple Example
		* getStyle({ gap, direction }) // { '--gap': gap, '--direction': direction }

		* @example
		* With Prefix
		* getStyle({ gap, direction }, 'flex') // { '--flex-gap': gap, '--flex-direction': direction }

		* @example
		* TSX Example
		```tsx
				import styles from './flex.module.scss';

				const { getStyle } = cssHelpers(styles); // Pass the styles from the CSS Module to the helper

				export type FlexProps = {
					children: React.ReactNode;
					direction?: CSSProperties['flexDirection'];
					wrap?: CSSProperties['flexWrap'];
				};

				export const Flex = ({ direction, gap }: FlexProps) => (
					<div className={styles.flex} style={getStyle({ gap, direction }, 'flex')}>{children}</div>
				);
		```
		@example
		* SCSS Example
		```scss
				.flex {
					--flex-gap: 0; // This will be overridden by the `gap` inline style
					--flex-direction: row; // This will be overridden by the `direction` inline style

					display: flex;
					flex-direction: var(--flex-direction);
					gap: var(--flex-gap);
				}
		```
	 */
	const getStyle = <Properties extends Record<string, string | number | undefined>>(
		/**
		 * The properties you want to be converted to CSS Variables
		 */
		properties: Properties | undefined,
		/**
		 * A Prefix in front of the variable name
		 */
		prefix?: string,
		/**
		 * Log out the style object.
		 */
		__DEBUG__?: boolean,
	) => {
		const style =
			properties &&
			(Object.entries(properties).reduce((acc, [key, value]) => {
				if (typeof value === 'string' && value.includes(' ')) {
					const splittedValue = value.split(' ');

					return {
						...acc,
						...splittedValue.reduce((splittedAcc, v, i) => {
							const keyName = `--${prefix ? `${prefix}-` : ''}${convertCamelToKebab(key)}`;

							return {
								...splittedAcc,
								[keyName]: splittedValue.map((_d, index) => `var(${keyName}-${index})`).join(' '),
								[`${keyName}-${i}`]: v,
							};
						}, {} as Record<string, string>),
					};
				}

				return {
					...acc,
					[`--${prefix ? `${prefix}-` : ''}${convertCamelToKebab(key)}`]: value,
				};
			}, {}) as CSSProperties);

		if (process.env.NODE_ENV === 'development' && __DEBUG__) {
			console.group('getStyle:');
			console.info(style);
			console.groupEnd();
		}

		return style;
	};

	return { getVariants, getStyle };
};
