/* eslint-disable no-template-curly-in-string */
import * as Form from 'react-hook-form';
import * as yup from 'yup';

const useForm = Form.useForm;

const da = {
	mixed: {
		default: '${path} er ugyldig',
		required: '${path} er et påkrævet felt',
		defined: '${path} skal være defineret',
		notNull: '${path} må ikke være null',
		oneOf: '${path} skal være en af følgende værdier: ${values}',
		notOneOf: '${path} må ikke være en af følgende værdier: ${values}',
	},
	string: {
		length: '${path} skal være præcis ${length} tegn',
		min: '${path} skal være mindst ${min} tegn',
		max: '${path} skal være højst ${max} tegn',
		matches: '${path} skal matche følgende: "${regex}"',
		email: '${path} skal være en gyldig email',
		url: '${path} skal være en gyldig URL',
		uuid: '${path} skal være en gyldig UUID',
		trim: '${path} skal være en trimmet streng',
		lowercase: '${path} skal være en streng med små bogstaver',
		uppercase: '${path} skal være en streng med store bogstaver',
	},
	number: {
		min: '${path} skal være større end eller lig med ${min}',
		max: '${path} skal være mindre end eller lig med ${max}',
		lessThan: '${path} skal være mindre end ${less}',
		moreThan: '${path} skal være større end ${more}',
		positive: '${path} skal være et positivt tal',
		negative: '${path} skal være et negativt tal',
		integer: '${path} skal være et heltal',
	},
	date: {
		min: 'Feltet ${path} skal være senere end ${min}',
		max: 'Feltet ${path} skal være tidligere end ${max}',
	},
	boolean: {
		isValue: 'Feltet ${path} skal være ${value}',
	},
	object: {
		noUnknown: 'Feltet ${path} har udefinerede nøgler: ${unknown}',
	},
	array: {
		min: 'Feltet ${path} skal have mindst ${min} elementer',
		max: 'Feltet ${path} skal have højst ${max} elementer',
		length: 'Feltet ${path} skal have ${length} elementer',
	},
};

yup.setLocale(da);

export { yupResolver } from '@hookform/resolvers/yup';
export {
	yup,
	useForm,
	Form,
};

export type FieldProps<TKey extends string> = {
	/**
	 * The ID of the field.
	 * This is used to identify the field in the form.
	 */
	id?: TKey;
	/**
	 * The placeholder of the field.
	 * This is used to describe an example of usage of field to the user before they've enter any input.
	 */
	placeholder?: string;
	autoFocus?: boolean;
	disabled?: boolean;
	required?: boolean;
};
