import en from './en';

export const languages = {
	en,
};

const defaultLanguage: keyof typeof languages = 'en';

// This whole logic should be replaced by a proper translation.
// @jdpnielsen I think my time is better spent building the UI than the i18n, as you probably already have a plan :)
// This is just a quick and dirty solution but provides you with an overview of where the translations are used.
export const translation = languages[defaultLanguage];

export const t = translation;
