import React from 'react';

if (typeof window !== 'undefined' && process.env.NODE_ENV === 'development') {
	// eslint-disable-next-line @typescript-eslint/no-var-requires, global-require
	const whyDidYouRender = require('@welldone-software/why-did-you-render');

	// eslint-disable-next-line no-console
	console.debug('Applying WhyDidYouRender, to help you locate unnecessary re-renders during development');

	whyDidYouRender(React, {
		trackAllPureComponents: true,
		trackHooks: true,
		logOwnerReasons: true,
		collapseGroups: true,
	});
}
