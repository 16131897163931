var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"813bbce4db1ee180b8c366d08cee1b9ed74779e4"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import * as Sentry from '@sentry/nextjs';
import { init } from '@sentry/nextjs';
import { Param0 } from 'type-zoo/types';

import Config from '@web/config';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

const options: Param0<typeof init> = {
	dsn: SENTRY_DSN || 'https://39f8fa82cd2b4f5291adc288e3274799@o351723.ingest.sentry.io/4504077665763328',
	// Adjust this value in production, or use tracesSampler for greater control
	tracesSampleRate: 0.1,
	environment: Config.APP_ENV,
	enabled: Config.APP_ENV !== 'local',
	beforeSend(event) {
		return event;
	},
};

Sentry.init(options);
